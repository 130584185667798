<template>
  <div class="audits-list">
    <div
      class="audit-quarter"
      v-for="(audits, quarter) in auditsByQuarter"
      :key="quarter"
    >
      <p class="list-heading">
        {{ quarter }} {{ audits.length ? ": " + audits.length : "" }}
      </p>
      <div class="audit-items" v-for="audit in audits" :key="audit.id">
        <p class="list-content">
          {{ audit.plan.internalId || audit.plan.ehcpId }}
        </p>
        <p class="list-content">{{ audit.createdAt | formatDate }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuarter, format, parseISO } from 'date-fns'

export default {
  name: 'SummaryItem',
  props: ['items'],
  filters: {
    formatDate (val) {
      let date = parseISO(val)
      console.log({ date })
      return format(new Date(val), 'PPP')
    }
  },
  computed: {
    completedAudits () {
      if (!this.items.length) {
        return []
      }
      return [...this.items].map(item => {
        return { ...item, quarter: getQuarter(parseISO(item.createdAt)) }
      })
    },
    auditsByQuarter () {
      return {
        Q1: this.completedAudits.filter(a => a.quarter === 1),
        Q2: this.completedAudits.filter(a => a.quarter === 2),
        Q3: this.completedAudits.filter(a => a.quarter === 3),
        Q4: this.completedAudits.filter(a => a.quarter === 4)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.audits-list {
}

.audit-quarter {
  width: 100%;
  min-height: 48px;
  padding: 0.5rem 1rem;
}
.audit-quarter:hover {
  background: rgba(0, 0, 0, 0.1);
}

.list-heading {
  font-size: 0.75rem;
  line-height: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
}
.list-content {
  font-size: 0.8rem;
  // cursor: pointer;
}

// .list-content:hover {
//   text-decoration: underline;
// }

.audit-items {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0.25rem 0;
}
</style>
