<template>
  <div class="modal is-active">
    <div
      class="modal-background"
      @click.stop="$emit('close-review-data-popup')"
    ></div>
    <div class="modal-content section-data" ref="view-container">
      <div class="title">
        View review Data -
        {{ review.assignment.plan.internalId || review.assignment.plan.ehcpId }}
      </div>
      <div class="sections-container">
        <div
          class="section-item"
          v-for="(answer, index) in review.answers"
          :key="index"
        >
          <div class="section-id">
            <p :style="style(answer)">{{ answer.theme }}</p>
          </div>
          <div class="section-grade" @click="viewSectionCriteria(answer)">
            {{ answer | formatGrade }}
          </div>
        </div>
        <div class="feedback-container" v-if="latestFeedback.length > 0">
          <div
            class="feedback-item"
            v-for="feedback in latestFeedback"
            :key="feedback.id"
          >
            <div class="item-icon">
              <i class="fas fa-laptop-house"></i>
            </div>
            <div class="item-type" @click="viewFeedback(feedback)">
              {{ formatType(feedback.type) }}
            </div>
          </div>
        </div>
      </div>
      <div class="section-criteria" v-if="selectedCriteria">
        <p>Theme: {{ selectedCriteria.theme }} - {{ title }}</p>
        <p class="section-comments" v-if="selectedCriteria.comments">{{ selectedCriteria.comments || null }}</p>
        <ul>
          <!-- <span>{{ grade | gradeDescription }}</span> -->
          <li
            v-for="(grade, index) in selectedCriteria.criteria"
            :key="index"
            class="qa-list"
          >
            <p class="question" v-if="!grade.iteration && selectedCriteria.theme === 6">
              Q{{ index + 1 }}:
              {{ questions.find((q) => q.questionIndex === grade.questionIndex)?.text }}
            </p>
            <p class="question" v-else-if="!grade.iteration">
              Q{{ grade.questionIndex + 1 }}:
              {{ questions[grade.questionIndex]?.text }}
            </p>
            <p class="question" v-else>
              Outcome {{ grade.iteration }}:
              {{ questions.find((q) => q.questionIndex === grade.questionIndex)?.text }}
            </p>
            <p class="answer">• {{ grade.text }}</p>
            <p v-if="grade.comments">Additional feedback: {{ grade.comments }}</p>
          </li>
        </ul>
      </div>
      <div class="feedback-responses" v-if="selectedFeedback">
        <p>Fedback Responses for {{ formatType(selectedFeedback.type) }}</p>
        <p class="section-comments">
          {{ selectedFeedback.comments.answer || null }}
        </p>
        <ul>
          <!-- <span>{{ grade | gradeDescription }}</span> -->
          <li
            v-for="({ answer, question }, index) in selectedFeedback.answers"
            :key="index"
            class="qa-list"
          >
            <p class="question">
              Q{{ index + 1 }}:
              {{ question }}
            </p>
            <p class="answer">{{ answer }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { outcomeGrades, formatGrades, formatTheme6Grades, getGradingColor } from '../../_helpers/grading'

export default {
  name: 'ViewDataPopup',
  props: ['review'],
  data () {
    return {
      selectedCriteria: null,
      selectedFeedback: null,
      surveyQuestions: null
    }
  },
  computed: {
    ...mapGetters({ themes: 'criteria/themes', surveys: 'feedback/surveys' }),
    title () {
      const theme = this.themes.find(set => set.theme === this.selectedCriteria.theme)
      return theme
        ? theme.title
        : ''
    },
    questions () {
      const theme = this.themes.find(set => set.theme === this.selectedCriteria.theme)
      return theme
        ? theme.questions
        : []
    },
    latestFeedback () {
      const allFeedback = this.review.feedback
      const allChildFeedback = allFeedback.filter(feedback => feedback.type === 'child_feedback')
      const allCarerFeedback = allFeedback.filter(feedback => feedback.type === 'carer_feedback')
      const sortedChildFeedback = allChildFeedback.sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt))
      const sortedCarerFeedback = allCarerFeedback.sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt))

      if (allChildFeedback.length > 1 || allCarerFeedback.length > 1) {
        return [sortedChildFeedback[0], sortedCarerFeedback[0]] 
      } else {
        return allFeedback
      }
    }
  },
  methods: {
    viewSectionCriteria (section) {
      this.$refs['view-container'].style.width = '850px'
      this.selectedCriteria = section
      this.selectedCriteria.criteria.sort((a, b) => a.questionIndex - b.questionIndex)
      if (this.selectedCriteria.theme === 6) {
        this.orderTheme6(this.selectedCriteria.criteria)
      }
      this.selectedFeedback = null
    },
    viewFeedback (feedback) {
      this.$refs['view-container'].style.width = '850px'
      const survey = this.surveys.find(survey => survey.type === feedback.type)
      this.surveyQuestions = survey.questions
      this.selectedFeedback = feedback
      this.selectedFeedback.answers = this.selectedFeedback.answers.map((answer, index) => {
        const question = this.surveyQuestions.find(question => question.index === index)
        return {
          answer: this.formatAnswerScore(answer),
          question: question.text
        }
      })

      this.selectedFeedback.comments = this.selectedFeedback.answers[this.selectedFeedback.answers.length - 1]
      this.selectedCriteria = null
    },
    style (theme) {
      const gradingColors = {
        Gold: '#FFCB4B',
        Silver: '#D4D6E4',
        Bronze: '#FF9328'
      }

      // const grades = {
      //   '#FFCB4B': { min: 2.359, max: 100 },
      //   '#D4D6E4': { min: 1.46, max: 2.35 },
      //   '#FF9328': { min: 0, max: 1.45 }
      // }

      const color = theme.theme === 6 ? getGradingColor(theme.outcome, formatTheme6Grades, 'Bronze') : getGradingColor(theme.outcome, formatGrades, 'Bronze')
      const backgroundColorHex = {
        background: outcomeGrades[color]
      }
      
      return backgroundColorHex
    },
    orderTheme6(orderedCriteria) {
      const outcomes = orderedCriteria.filter((c) => c.iteration >= 0)
      const question6 = orderedCriteria.find((c) => c.questionIndex === 6)
      const question7 = orderedCriteria.find((c) => c.questionIndex === 7)
      let theme6 = [orderedCriteria[0]]
      if (question6) theme6.push(question6)
      if (question7) theme6.push(question7)
      theme6.push(orderedCriteria[1])
      theme6 = [...theme6, ...outcomes]
      this.selectedCriteria.criteria = theme6
    },
    formatAnswerScore (score) {
      const answers = {
        5: 'Strongly Agree',
        4: 'Agree',
        3: 'Unsure',
        2: 'Disagree',
        1: 'Strongly Disagree'
      }
      return answers[score]
    },
    formatType (type) {
      if (type === 'carer_feedback') {
        return 'Carer Feedback'
      } else if (type === 'child_feedback') {
        return 'C/YP Feedback 1'
      } else if (type === 'child_feedback_2') {
        return 'C/YP Feedback 2'
      }
    },
  },
  mounted () {
    this.selectedCriteria = null
    this.$refs['view-container'].style.width = '400px'
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/modal.scss";

.modal-content.section-data {
  // min-width: 90vw;
  width: 400px;
  // min-width: 400px;
  // max-width: 0;
  display: grid;
  grid-template-areas:
    "title title"
    "grades criteria";
  column-gap: 0.5rem;
  transition: all 300ms;
}

.modal-content.section-data > .title {
  grid-area: title;
  margin-bottom: 1rem;
}

.sections-container {
  grid-area: grades;
  padding: 0.5rem;
}
.section-criteria {
  grid-area: criteria;
  padding: 0.5rem;
  ul {
    list-style: none;
    padding-inline-start: 1rem;
    span {
      font-size: 1rem;
      text-decoration: underline;
    }
    li {
      font-size: 0.875rem;
      padding: 0.5rem;
    }
  }
}

.section-criteria > p {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
}

.section-comments {
  font-size: 0.9rem !important;
  text-align: left;
  padding-top: 1rem;
  font-size: 400;
  font-style: italic;
}

.section-comments::before {
  content: "Comments: ";
  font-style: normal;
}

.section-item {
  width: 100%;
  display: grid;
  grid-template-columns: 110px 1fr;
  column-gap: 0.5rem;
  div {
    padding: 0.25rem;
    display: flex;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      min-width: 36px;
      padding: 6px 8px;
      border-radius: 30px;
      text-transform: uppercase;
      background: #f2f2f2;
    }
  }
  .section-id {
    justify-self: center;
  }
  .section-grade {
    padding-left: 1rem;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.feedback-container {
  display: grid;
  padding-top: 1rem;
  row-gap: 0.5rem;
}

.feedback-item {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 110px 1fr;
  column-gap: 0.5rem;
  align-items: center;
  .item-icon {
    padding: 0.35rem;
    border: 1px black solid;
    border-radius: 100%;
    justify-self: center;
  }
  .item-type {
    padding-left: 1rem;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

li.qa-list {
  &:nth-child(even) {
    background-color: #eeedec;
  }
  .question {
    font-style: italic;
  }
  .answer {
    font-weight: 600;
  }
  .comments {
    font-weight: 600;
  }
}
</style>
