<template>
  <div class="live-reviews-container">
    <SectionHeader title="Live Annual Reviews">
      <template v-slot:filters>
        <button class="button survey-links" @click="linksCSV">
          Export Links
        </button>
      </template>
      <template v-slot:searchbar>
        <div class="search-bar field no-margin">
          <span class="icon input-icon">
            <i class="fas fa-search"></i>
          </span>
          <input
            type="text"
            class="input has-icon"
            placeholder="Search"
            v-model="searchTerm"
            @input="search"
          />
        </div>
      </template>
    </SectionHeader>
    <div style="flex: 1">
      <div class="dateFilter">
        Assigned from:
        <input type="date" name="search" v-model="dateRange.start" />
        To:
        <input type="date" name="search" v-model="dateRange.end" />
      </div>
      <div class="list__options">
        <label for="page-size"> Results per page: </label>&nbsp;
        <input
          class="input"
          type="number"
          step="5"
          name="page-size"
          v-model.number="limit"
        />
      </div>
    </div>
    <div class="plan-list">
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr class="table-header">
              <th @click="sortBy('plan.internalId')">*C/YP ID</th>
              <th></th>
              <th @click="sortBy('user.name')">Assigned to</th>
              <th @click="sortBy('plan.teamRegion')">Team / Region</th>
              <th @click="sortBy('plan.caseOfficer')">SEN Case Officer</th>
              <th @click="sortBy('createdAt')">Assigned on</th>
              <th @click="sortBy('completedAt')">Completed on</th>
              <th @click="sortBy('completedAt')"></th>
              <!-- <th>Key Stage</th> -->
              <!-- <th>Primary Need</th> -->
              <!-- <th>Setting Name</th> -->
              <!-- <th>Setting Type</th> -->
              <!-- <th>Finalised Date</th> -->
              <!-- <th>Current Social Care</th> -->
            </tr>
          </thead>
          <tbody name="table-row" is="transition-group">
            <tr
              v-for="assignment in paginatedAnnualReviews"
              :key="assignment.id"
            >
              <td
                @click.stop="didClickShowAuditData(assignment.id)"
                class="link-hover"
              >
                {{ assignment.plan.internalId }}
              </td>
              <td>
                <i
                  v-if="isModeratedPlan(assignment)"
                  class="fas fa-user-friends"
                  style="color: #cecece"
                ></i>
              </td>
              <td>{{ assignment.user.name }}</td>
              <td>{{ assignment.plan.teamRegion || "-" }}</td>
              <td>{{ assignment.plan.caseOfficer }}</td>
              <td>{{ assignment.createdAt | formatDate }}</td>
              <td>{{ assignment.completedAt | formatDate }}</td>
              <td>
                <div class="context-items">
                  <span class="completed" v-show="assignment.completedAt">
                    <!-- <i class="far fa-check-circle"></i> -->
                    <i class="fas fa-check-circle" style="color: green"></i>
                  </span>
                  <span
                    class="survey"
                    @click="showSurveyModal(assignment.planId)"
                  >
                    <i class="fas fa-laptop-house"></i>
                  </span>
                  <span
                    class="pdf-report"
                    v-show="assignment.completedAt"
                    @click="downloadReport(assignment.id)"
                  >
                    <i class="far fa-file-pdf"></i>
                  </span>
                  <span class="archive" @click="archive(assignment.id)">
                    <i class="far fa-trash-alt"></i>
                  </span>
                </div>
              </td>
              <!-- <td class="archive" @click="archive(assignment.id)"></td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="list__controls">
        <vue-paginate-al
          :totalPage="totalPages"
          :currentPage="currentPage"
          @btnClick="goToFunction"
          customActiveBGColor="#278a78"
          :pageRange="4"
          nextText=">"
          prevText="<"
        >
        </vue-paginate-al>
      </div>
    </div>
    <b></b>
    <DataPopup
      :review="reviewToView"
      @close-review-data-popup="showAuditPopup = false"
      v-if="showAuditPopup"
    />
    <Modal
      title="Survey Link"
      @modal-cancel="canShowSurveyModal = !canShowSurveyModal"
      @modal-close="canShowSurveyModal = !canShowSurveyModal"
      v-if="canShowSurveyModal"
      buttonText="OK"
    >
      <div v-for="form in formLinks" :key="form.type">
        <div class="link-group">
          <label>{{ form.description }}</label>
          <span>
            <input type="text" disabled :value="planLink(form.type)" />
            <button
              class="button"
              @click="copySurveyLink(form.type)"
              :ref="`ref_${form.type}`"
            >
              Copy
            </button>
          </span>
        </div>
        <br />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SectionHeader from "@/components/SectionHeader";
import DataPopup from "@/views/admin/DataPopup.vue";
import Modal from "@/components/common/GenericModal";

import SearchUsersMixin from "@/_mixins/search-users.mixin";
import DateFilterMixin from "@/_mixins/date-filter.mixin";

import Fuse from "fuse.js";
import isBefore from "date-fns/isBefore/index";
import isAfter from "date-fns/isAfter/index";

export default {
  name: "LiveEHCPs",
  components: { SectionHeader, DataPopup, Modal },
  mixins: [SearchUsersMixin, DateFilterMixin],
  data() {
    return {
      sortKey: "createdAt",
      searchTerm: "",
      fuse: null,
      searchResults: [],
      limit: 10,
      offset: 0,
      reviewToView: null,
      showAuditPopup: false,
      orderToggle: false,
      lastPage: 0,
      dateRange: {
        start: null,
        end: null
      },
      feedbackUrl: null,
      canShowSurveyModal: false,
      formLinks: [
        { type: "child_feedback", description: "C/YP Form One" },
        { type: "child_feedback_2", description: "C/YP Form Two" },
        { type: "carer_feedback", description: "Parent/Carer Form" }
      ]
    };
  },
  watch: {
    assignments() {
      const plans = [...this.assignments].map(a => {
        return {
          ...a.plan,
          name: a.user.name,
          serviceArea: a.user.service,
          assignmentId: a.id
        };
      });
      this.fuse = new Fuse(plans, {
        includeScore: true,
        includeMatches: true,
        ignoreLocation: true,
        threshold: 0.5,
        // keys: ['internalId', 'caseOfficer', 'name', 'serviceArea']
        keys: ["internalId", "name", "serviceArea", "email", "caseOfficer"]
      });
    },
    showAuditPopup(val) {
      if (!val) {
        this.reviewToView = null;
      }
    },
    offset(newValue, oldValue) {
      this.offset = newValue;
    },
    limit(newValue, oldValue) {
      this.limit = newValue;
    }
  },
  computed: {
    ...mapGetters({
      assignments: "assignment/assignments",
      reviews: "assignment/reviews"
    }),
    dormantAssignments() {
      return [...this.assignments].filter(
        assignment =>
          !this.reviews.some(review => review.assignmentId === assignment.id)
      );
    },
    activeAssignments() {
      return [...this.assignments]
        .filter(assignment =>
          this.reviews.some(review => review.assignmentId === assignment.id)
        )
        .map(assignment => {
          assignment.completedAt =
            assignment.completedAt ||
            this.reviews.find(review => review.assignmentId === assignment.id)
              .completedAt;
          assignment.review = this.reviews.find(
            review => review.assignmentId === assignment.id
          );
          return assignment;
        });
    },
    allAssignments() {
      return [...this.dormantAssignments, ...this.activeAssignments];
    },
    plans() {
      return [...this.allAssignments].map(a => a.plan);
    },
    results() {
      if (!this.searchTerm) {
        return [...this.allAssignments]
          .filter(a => !a.archived)
          .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));
      }
      return [...this.allAssignments]
        .filter(a => this.searchResults.includes(a.id))
        .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));
    },
    orderedAndFiltered() {
      if (!this.results) {
        return [];
      }

      const keys = this.sortKey.split(".");
      let assignments = [...this.results];

      if (this.dateRange.start) {
        assignments = assignments.filter(({ createdAt }) =>
          isAfter(
            new Date(createdAt.slice(0, 10)),
            new Date(this.dateRange.start)
          )
        );
      }

      if (this.dateRange.end) {
        assignments = assignments.filter(({ createdAt }) =>
          isBefore(
            new Date(createdAt.slice(0, 10)),
            new Date(this.dateRange.end)
          )
        );
      }

      assignments.sort((a, b) => {
        for (let i = 0; i < keys.length; i++) {
          a = a[keys[i]];
          b = b[keys[i]];
        }
        return this.orderToggle ? (a < b ? -1 : 1) : a > b ? -1 : 1;
      });

      return assignments;
    },
    totalPages() {
      return Math.ceil(this.orderedAndFiltered.length / this.limit);
    },
    currentPage() {
      return this.offset / this.limit + 1;
    },
    paginatedAnnualReviews() {
      const limit = this.offset + this.limit;
      return this.orderedAndFiltered.slice(this.offset, limit);
    }
  },
  methods: {
    ...mapActions({ archiveAssignment: "assignment/archive" }),
    /**
     * Exports the sorted and filtered assignments list to CSV.
     * 
     * Appends the survey links to each row.
     */

    linksCSV() {
      // CSV Array
      const csvRows = [
        'C/YP ID,Assigned To,Team/Region,SEN Case Officer,Older child survey link,Young child survey link,Parent/Carer survey link'
      ];

   
      // Add rows from sorted and filtered array.
      this.orderedAndFiltered.forEach((assignment) => {
        // Loop types to generate links.
        const surveyLinks = this.formLinks.map(({type}) => { return this.surveyLink(type, assignment.planId) }).join(',');
        
        // Compile data and links into a row.
        csvRows.push(
          `${assignment.plan.internalId},${assignment.user.name},${assignment.plan.teamRegion},${assignment.plan.caseOfficer},${surveyLinks}`
        )
      })

      

      // Prepare metadata.
      window.URL = window.webkitURL || window.URL;
      var csvFile = new Blob([csvRows.join('\n')], {type: 'text/csv'});

      // Create download anchor.
      var a = document.createElement('a');
      a.download = 'AR Survey Links.csv';
      a.href = window.URL.createObjectURL(csvFile);
      a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
      document.body.appendChild(a);
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(csvFile);
      document.body.removeChild(a);
    },
    sortBy(toSort) {
      this.offset = 0;
      this.orderToggle = this.sortKey === toSort ? !this.orderToggle : true;
      // this.sortKey = 'createdAt'
      this.sortKey = toSort;
    },
    search(event) {
      if (!this.fuse) {
        const plans = [...this.allAssignments].map(a => {
          return {
            ...a.plan,
            name: a.user.name,
            serviceArea: a.user.service,
            assignmentId: a.id
          };
        });
       
        this.fuse = new Fuse(plans, {
          includeScore: true,
          includeMatches: true,
          ignoreLocation: true,
          threshold: 0.5,
          keys: ["internalId", "name", "serviceArea", "email"]
        });
      }
      const results = this.fuse.search(this.searchTerm);
      this.searchResults = results.map(r => r.item.assignmentId);
    },
    didClickShowAuditData(assignmentId) {
      this.reviewToView = this.reviews.find(
        review => review.assignmentId === assignmentId
      );
      if (this.reviewToView) {
        this.showAuditPopup = true;
      }
    },
    isModeratedPlan(assignment) {
      return (
        this.orderedAndFiltered.find(
          a => a.id !== assignment.id && a.plan.id === assignment.plan.id
        ) !== undefined
      );
    },
    async archive(assignmentId) {
      await this.archiveAssignment(assignmentId);
    },
    async downloadReport(assignmentId) {
      const { id: reviewId } = this.reviews.find(
        review => review.assignmentId === assignmentId
      );
      await this.downloadReviewReport(reviewId);
    },
    async downloadReviewReport(reviewId) {
      const { data: base64String } = await this.ApiService.generateReviewReport(
        reviewId
      );
      const blob = this.convertBase64StringToBlob(base64String);
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    },
    convertBase64StringToBlob(base64String) {
      const binaryString = window.atob(base64String);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return new Blob([bytes], { type: "application/pdf" });
    },
    showSurveyModal(planId) {
      this.planId = planId;
      this.canShowSurveyModal = true;
    },
    // reviewLink(type) {
    //   return `${process.env.VUE_APP_SITE_URL}/opinion/${type}/${this.reviewId}`
    // },
    planLink(type) {
      return this.surveyLink(type, this.planId);
    },
    surveyLink(type, planId) {
      return `${process.env.VUE_APP_SITE_URL}/opinion/${type}/${planId}`;
    },
    async copySurveyLink(type) {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.planLink(type));
        const button = this.$refs[`ref_${type}`][0];
        button.innerText = "Copied!";
        setTimeout(() => (button.innerText = "Copy"), 2000);
      } else {
        alert(
          "Sorry, your device won't let us copy to your clipboard. Please select the text (or triple click it), right-click, and choose 'Copy'"
        );
      }
    },
    previousPage() {
      this.offset = this.offset - this.limit < 0 ? 0 : this.offset - this.limit;
    },
    nextPage() {
      this.offset =
        this.offset + this.limit > this.orderedAndFiltered.length
          ? 0
          : this.offset + this.limit;
    },
    goToPage(page) {
      const offset = page * this.limit;
      if (offset > this.orderedAndFiltered.length) {
        this.offset = offset - this.offset;
      } else {
        this.offset = offset;
      }
    },
    goToFunction(value) {
      this.offset =
        value > this.lastPage
          ? this.offset + this.limit
          : this.offset - this.limit;
      this.lastPage = value;
    }
  }
};
</script>

<style lang="scss">
.modal > .modal-content {
  max-width: 800px;
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.upload-container {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 46px repeat(3, auto);
  grid-auto-flow: unset;
  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    padding-left: 1rem;
  }
  &__subtitle {
    font-size: 1rem;
    font-weight: 400;
    padding-left: 1rem;
    &--bold {
      font-weight: 600;
    }
  }
  &__dropbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border: 1px dashed lightgrey;
    border-radius: $border-radius;
    padding: 1rem;
    margin: 1rem;
    font-size: 1.25rem;
  }
  .is-active {
    background: #42ab983b;
  }
  .button-row {
    padding-top: $padding1x;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

td.archive {
  width: 3rem;
  // background: rgba(0, 0, 0, 0);
  color: transparent;
  transition: all 200ms ease-in-out;
}

.table-row-enter-active,
.table-row-leave-active {
  transition: all 350ms;
}

.table-row-enter,
.table-row-leave-to {
  opacity: 0;
}

.table-row-move {
  transition: all 300ms;
}

// td.archive > .fa-2x {
//   font-size: 1.5rem;
// }

.table .table-header > th {
  // color: red;
  cursor: pointer;
}

.table > tbody > tr:hover {
  // .archive {
  //   background: rgba(232, 49, 86, 1);
  //   color: white;
  // }
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.sub-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: search;
  .subtitle {
    width: 100%;
  }
}

.search-params {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.dateFilter {
  flex-grow: 1;
  flex-shrink: 0;
  padding-right: 0.5rem;
}

.search-bar {
  max-width: 400px;
}

.context-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem;
  span {
    font-size: 1.2rem;
    // width: 1rem;
    // height: 1rem;
  }
  .pdf-report:hover,
  .survey:hover,
  .archive:hover {
    transform: scale(1.3);
  }

  .archive:hover {
    color: red;
  }
}

.link-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1 1 auto;
  // height: 5rem;
  label {
    width: 100%;
    margin: unset;
  }
  span {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    height: 2.5rem;
    justify-content: center;
  }
  input {
    width: calc(100% - 4rem);
    // height: 2.5rem;
    padding-left: 0.5rem;
    border: 1px solid #3c3c3b3a;
    border-radius: unset;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  button {
    padding: 0;
    border-radius: unset;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.control {
  display: flex;
  place-items: center;
  place-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eeedec;
  border-radius: 4px;
  background: transparent;
  box-shadow: none;
  transition: box-shadow 200ms;
  font-weight: 600;
  &:hover {
    box-shadow: 0 4px 8px #eeedec;
  }
}

.list__options {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: baseline;
  .input {
    max-width: 64px;
    padding-left: 1rem;
    margin-left: 0.5rem;
    font-weight: 600;
  }
}

.list__controls,
.pagination-control {
  display: flex;
}

.list__controls {
  grid-column: 1 / 3;
  justify-content: center;
  margin-bottom: 20px;
}

.pagination-control .control {
  border: none;
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.current-page {
  color: blue;
  text-decoration: underline;
}

/* Normalises button styling to fix next to search bar. */
.button.survey-links {
  margin-top: -5px;
  height: 2.6rem;
  line-height: 1rem;
}

// /deep/ .pagination li a {
//   color: black !important;
// }

// /deep/ .pagination li:hover {
//   a {
//     text-decoration: underline;
//   }
// }

// /deep/ .pagination .active a {
//   color: white !important;
// }
</style>
