<template>
  <div class="carer-feedback-container">
    <div class="carer-feedback-box">
      <div class="title">
        <h3 class="survey-title">{{ currentSurvey.title }}</h3>
      </div>
      <div>
        <div
          v-for="(question, index) in questions"
          :key="question.text"
          :index="index"
        >
          <h4>{{ question.index + 1 }}. {{ question.text }}</h4>
          <!-- Answers for rating scale -->
          <div
            v-if="
              question.type === 'rating_scale' &&
                currentSurvey.type !== 'child_feedback_2'
            "
          >
            <div v-for="rating in question.ratingScale" :key="rating.label">
              <input
                type="radio"
                :name="'question-' + question.index"
                :value="rating.value"
                v-model="answers[question.index]"
              />
              <label :for="'question-' + question.index">{{
                rating.label
              }}</label>
            </div>
          </div>
          <div v-else-if="currentSurvey.type === 'child_feedback_2'">
            <div v-for="rating in question.ratingScale" :key="rating.label">
              <!-- <input
                type="radio"
                :name="'question-' + question.index"
                :value="rating.value"
                v-model="answers[question.index]"
              />
              <label :for="'question-' + question.index">{{
                rating.label
              }}</label> -->
              <button>
                {{ rating.emoji }}
                {{ rating.label }}
              </button>
            </div>
          </div>
          <div v-if="showFeedbacks && question.type !== 'input'">
            <div v-if="showFeedback(index)">
              <textarea
                class="textarea"
                placeholder="Additional feedback"
                v-model="feedback[question.index]"
              />
            </div>
            <div v-else>
              <span @click="onShowFeedback(index)"
                ><span class="icon input-icon">
                  <i class="fa fa-plus"></i>
                </span>
                Additional Comments</span
              >
            </div>
          </div>

          <!-- Answers for text input -->
          <div v-if="question.type === 'input'">
            <textarea
              class="textarea"
              :placeholder="question.input.placeholder"
              v-model="answers[question.index]"
            />
          </div>
        </div>
        <SectionFooter
          :nextButtonText="buttonText"
          :showBackButton="hasSecondPage && page == 1"
          @nextClicked="submitFeedback"
          @backClicked="page = 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SectionFooter from "@/components/SectionFooter";

export default {
  name: "FeedbackForm",
  components: { SectionFooter },
  props: {
    planId: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      answers: [],
      feedback: {},
      commentsShowing: [],
      page: 0,
    };
  },
  computed: {
    ...mapGetters({
      surveys: "feedback/surveys",
    }),
    showFeedbacks() {
      return this.type == "carer_feedback";
    },
    currentSurvey() {
      return this.surveys.find((obj) => obj.type === this.type);
    },
    hasSecondPage(){
      return this.currentSurvey.questions.filter(({secondPage}) => secondPage).length;
    },
    buttonText(){
      if(!this.hasSecondPage || this.page == 1)
        return "Submit"
      else return "Next"
    },
    questions() {
      if(this.page == 0)
        return this.currentSurvey.questions.filter(({secondPage}) => !secondPage);
      else
        return this.currentSurvey.questions.filter(({secondPage}) => secondPage);
    },
  },
  methods: {
    onShowFeedback(index) {
      this.commentsShowing = [...this.commentsShowing, index];
    },
    showFeedback(index) {
      return this.commentsShowing.includes(index);
    },
    async submitFeedback() {
      if (
        this.answers.length < this.questions.filter((q) => !q.optional).length
      ) {
        return alert("All fields must be filled in");
      }
      if(this.hasSecondPage && this.page == 0) {
        this.page = 1
        return
      }

      let feedback = {
        planId: this.planId,
        type: this.currentSurvey.type,
        answers: this.answers,
        feedback: this.feedback,
      };

      try {
        await this.ApiService.submitReviewFeedback(feedback);
        this.$router.push({ name: "FeedbackSubmittedView" });
      } catch (error) {
        this.error =
          "There was an error submitting your feedback. Please try again.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.carer-feedback-container {
  background-color: white;
  height: 100%;
  padding: $padding1x;
  .carer-feedback-box {
    width: $container-width;
    margin: 0 auto;
    padding: 50px 0;
    .title {
      .survey-title {
        margin: 0;
        font-weight: 600;
        font-size: 1.25rem;
      }
    }
    textarea.textarea {
      border-radius: $content-border-radius;
      border: $content-border;
      height: 100%;
      font-size: 1rem;
      outline: none;
      resize: none;
      overflow: auto;
      padding: 10px;
      width: 100%;
      &,
      &::placeholder {
        font-family: AllRoundGothicBook;
      }
    }
  }
}
</style>
