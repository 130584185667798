<template>
  <div class="user-manager-container">
    <SectionHeader title="User Management">
      <template v-slot:searchbar>
        <div class="search-bar field no-margin">
          <span class="icon input-icon">
            <i class="fas fa-search"></i>
          </span>
          <input
            type="text"
            class="input has-icon"
            placeholder="Find user"
            v-model="searchTerm"
            @input="search"
          />
        </div>
      </template>
    </SectionHeader>
    <!-- List of Users -->
    <div class="user-list">

      <div class="info-row info-row--flush">
        <button class="button" @click="showAddUserModal = true">
          Create new user
        </button>
      </div>
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Service Area</th>
              <th>Email</th>
              <th>Date Added</th>
              <th>Access Level</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody name="table-row" is="transition-group">
            <tr
              v-for="user in filteredUsers"
              :key="user.id"
              @click="setActiveUser(user)"
            >
              <td>{{ user.name }}</td>
              <td>{{ user.service }}</td>
              <td>{{ user.email }}</td>
              <td>{{user.createdAt | formatDate}}</td>
              <td>{{ user.role }}</td>
              <td>{{ user.active }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Create User Modal -->
    <Modal
      title="Create new user"
      v-model="showAddUserModal"
      @save="addNewUser"
    >
      <TextField v-model="newUser.name" label="Name" icon="far fa-user" />
      <TextField
        v-model="newUser.service"
        label="Service Area"
        icon="far fa-building"
      />
      <TextField
        v-model="newUser.email"
        label="Email"
        type="email"
        icon="far fa-envelope"
      />
      <SelectField
        v-model="newUser.role"
        :options="roles"
        label="Access Level"
        icon="fas fa-shield-alt"
      />
    </Modal>
    <!-- View/Edit User Modal -->
    <div class="modal" :class="{ 'is-active': showEditUserModal }">
      <div class="modal-background" @click.stop="showEditUserModal = false" />
      <div class="modal-content">
        <div class="modal-title">View Details</div>
        <div class="modal-body">
          <div class="user-details-container">
            <div class="user-details">
              <TextField
                v-model="activeUser.name"
                label="Name"
                icon="far fa-user"
              />
              <TextField
                v-model="activeUser.service"
                label="Service Area"
                icon="far fa-building"
              />
              <TextField
                v-model="activeUser.email"
                label="Email"
                type="email"
                icon="far fa-envelope"
              />
              <SelectField
                v-model="activeUser.role"
                :options="roles"
                label="Access Level"
                icon="fas fa-shield-alt"
              />
              <div class="field">
                <button
                  class="button is-gradient"
                  @click="updateUserDetails(activeUser)"
                >
                  Update
                </button>
              </div>
              <hr />
              <div class="field">
                <button
                  class="button"
                  @click="sendPasswordReset(activeUser.email)"
                >
                  Send password reset
                </button>
              </div>
              <div class="field" v-if="activeUser.active">
                <button
                  class="button is-danger"
                  @click="deactivateUser(activeUser.id)"
                >
                  Deactivate User
                </button>
              </div>
              <div class="field" v-if="!activeUser.active">
                <button
                  class="button is-info"
                  @click="activateUser(activeUser.id)"
                >
                  Activate User
                </button>
              </div>
            </div>
            <div class="user-audit-details">
              <SummaryItem :items="assignmentsForUserId(activeUser.id)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchUsersMixin from '@/_mixins/search-users.mixin'
import SectionHeader from '@/components/SectionHeader'
import SelectField from '@/components/common/SelectField'
import TextField from '@/components/common/TextField'
import Modal from '@/components/common/Modal'
import SummaryItem from '@/views/admin/SummaryItem'
import { mapGetters, mapActions } from 'vuex'
import { Role } from '@/_helpers/role'
import DateFilterMixin from '@/_mixins/date-filter.mixin'

export default {
  name: 'UserManagement',
  mixins: [SearchUsersMixin, DateFilterMixin],
  components: { SectionHeader, Modal, TextField, SelectField, SummaryItem },
  data () {
    return {
      newUser: {
        name: null,
        service: null,
        email: null,
        role: null
      },
      activeUser: {
        id: null,
        name: null,
        service: null,
        email: null,
        role: null,
        active: null
      },
      roles: [...Object.values(Role).filter(role => role !== Role.Super)],
      showAddUserModal: false,
      showEditUserModal: false
    }
  },
  computed: {
    ...mapGetters({
      users: 'organisation/users',
      user: 'user/user',
      assignments: 'assignment/assignments',
    })
  },

  methods: {
    ...mapActions({
      createNewUser: 'organisation/createNewUser',
      updateUser: 'organisation/updateUser'
    }),
    addNewUser () {
      let userTrim = {
          name: this.newUser || this.newUser.name ? this.newUser.name.trim() : '' ,
          service: this.newUser || this.newUser.service ? this.newUser.service.trim() : '' ,
          email: this.newUser || this.newUser.email ? this.newUser.email.trim() : '' ,
          role: this.newUser || this.newUser.role ? this.newUser.role.trim() : '' ,
        }

      const hasFilledAllFields = Object.keys(userTrim)
        .map(key => (key = userTrim[key].length > 0))
        .filter(prop => prop)
      if (hasFilledAllFields.length < 4) {
        return alert('All fields must be filled in')
      }

      try {
        const organisationId = this.user.organisationId
        const user = { ...userTrim, active: true }
        this.createNewUser({user, organisationId })
        this.newUser = {
          name: null,
          service: null,
          email: null,
          role: null,
        }
        this.showAddUserModal = false
      } catch (err) {
        alert('Sorry we had a problem saving. Please try again')
      }
    },
    setActiveUser (user) {
      this.activeUser = user
      this.showEditUserModal = true
    },
    updateUserDetails (user) {
      try {
        const organisationId = this.user.organisationId
        this.updateUser({ user, organisationId })
        this.showEditUserModal = false
      } catch (error) {
        alert('Sorry we had a problem saving. Please try again')
      }
    },
    async sendPasswordReset (email) {
      try {
        await this.ApiService.requestPasswordReset(email)
        this.showEditUserModal = false
      } catch (error) {
        alert('Sorry we had a problem requesting password reset. Please try again')
      }
    },
    deactivateUser (userId) {
      try {
        const organisationId = this.user.organisationId
        const user = { id: userId, active: false }
        this.updateUser({ user, organisationId })
        this.showEditUserModal = false
      } catch (error) {
        alert('Sorry we had a problem deactivating. Please try again')
      }
    },
    activateUser (userId) {
      try {
        const organisationId = this.user.organisationId
        const user = { id: userId, active: true }
        this.updateUser({ user, organisationId })
        this.showEditUserModal = false
      } catch (error) {
        alert('Sorry we had a problem activating. Please try again')
      }
    },
    assignmentsForUserId (id) {
      return [...this.assignments].filter(assignment => assignment.userId === id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.user-details-container {
  display: flex;
  flex-direction: row;
  .user-details {
    width: 350px;
    margin-right: 1rem;
  }
  .user-audit-details {
    width: 450px;
    min-height: 100%;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .is-danger {
    background: red;
  }
  .is-info {
    background: #007bff;
  }
}
</style>
