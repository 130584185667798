<template>
  <DashboardLayout>
    <template v-slot:sidebar>
      <SideBar :items="sidebarItems" />
    </template>
    <template v-slot:topnav>
      <TopNav :role="role" />
    </template>
    <template v-slot:content>
      <router-view />
    </template>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from '@/components/common/DashboardLayout.vue'
import SideBar from '@/components/nav/SideBar.vue'
import TopNav from '@/components/nav/TopNav.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Dashboard',
  components: { DashboardLayout, SideBar, TopNav },
  data () {
    return {
      sidebarItems: [
        {
          text: 'User Management',
          route: '/admin/users',
        },
        {
          text: 'Upload AR Audits',
          route: '/admin/upload',
        },
        {
          text: 'Assign Reviews',
          route: '/admin/assign',
        },
        {
          text: 'View Annual Reviews',
          route: '/admin/list',
        },
        {
          text: 'Live Annual Reviews',
          route: '/admin/live',
        },
        {
          text: 'Moderated Reviews',
          route: '/admin/moderated',
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      role: 'user/role'
    })
  },
  methods: {
    ...mapActions({
      fetchAssignments: 'assignment/fetchAssignments',
      fetchPlans: 'plan/fetchPlans',
      fetchUsers: 'organisation/fetchUsers',
    })
  },
  async mounted () {
    await this.fetchAssignments()
    await this.fetchPlans()
    await this.fetchUsers()
  }
}
</script>
