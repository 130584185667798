<template>
  <div class="moderation-container">
    <SectionHeader title="Moderated Annual Reviews">
      <template v-slot:searchbar>
        <div class="search-bar field no-margin">
          <span class="icon input-icon">
            <i class="fas fa-search"></i>
          </span>
          <input
            type="text"
            class="input has-icon"
            placeholder="Search"
            v-model="searchTerm"
            @input="search"
          />
        </div>
      </template>
    </SectionHeader>
    <div class="list__container">
      <div class="list__options">
        <label for="page-size"> Results per page: </label>&nbsp;
        <input
          class="input"
          type="number"
          step="5"
          name="page-size"
          v-model.number="limit"
        />
      </div>
      <div class="list__header">
        <p>C/YP ID</p>
        <p>Created</p>
        <p>Updated</p>
        <p>No. Auditors</p>
        <p>Differences</p>
      </div>
      <div class="list__content" v-if="moderatedPlans.length">
        <div
          class="list__content--item"
          role="button"
          v-for="({ assignments, reviews, plan }, index) in paginatedPlans"
          :key="index"
          @click="expand(index)"
        >
          <!-- {{ assignments }} -->
          <!-- {{ plan }} -->
          <div class="collapsed-data">
            <a>{{ plan.internalId }}</a>
            <p>{{ plan.createdAt | formatDate }}</p>
            <p>{{ plan.updatedAt | formatDate }}</p>
            <span class="status-pill">
              {{ assignments.length }}
            </span>
            <!-- <p>{{ numberOfDiffs(reviews) }}</p> -->
            <p>{{ numberOfDiffs(reviews) }}</p>
          </div>
          <div
            class="expanded-data"
            :class="{ 'show-expanded': expandedItem === index }"
          >
            <div class="plan-details" v-if="expandedItem === index">
              <!-- Table implementation -->
              <div
                v-if="viewingAudit.reviews.length > 0"
                class="table-data-container"
              >
                <div class="header">
                  <div></div>
                  <div
                    v-for="(review, rIndex) in viewingAudit.reviews"
                    :key="review.id">
                      <div
                        class="section-header grades"
                        :class="
                          `outcome__${$options.filters.formatOutcome(
                            review.outcome
                          )}`
                        "
                      >
                        <p>
                          {{ review.outcome | formatOutcome }}
                          <span class="audited-by">- Audited by: </span
                          ><span class="review-author">{{
                            reviewAuthor(review.userId)
                          }}</span>
                        </p>
                      </div>
                  </div>
                  <div></div>
                </div>
                <div v-for="(section, index) in themes">
                  <div>Theme {{ section.theme }}</div>
                  <div v-for="review in viewingAudit.reviews" :key="review.id">
                      <a
                        class="section-result"
                        @click.stop="showResults(review)"
                        v-if="review.answers[index]">
                         {{ review.answers[index] | formatGrade }} grade
                      </a>
                  </div>
                  <div v-if="diffs[section.theme]"><div class="round" :style="showDiffs(diffs[section.theme])"></div></div>
                </div>
              </div>
              <div class="empty-state-container" v-else>
                <h3>No audit reviews conducted yet.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list__content empty" v-if="!moderatedPlans.length">
        <h3 class="text-center">No moderated annual reviews.</h3>
        <p class="text-center">Plans will show here once they have 2 or more completed reviews.</p>
      </div>
      <div class="list__controls">
        <vue-paginate-al
          :totalPage="totalPages"
          :currentPage="currentPage"
          @btnClick="goToFunction"
          customActiveBGColor="#278a78"
          :pageRange="4"
          nextText=">"
          prevText="<"
        >
        </vue-paginate-al>
      </div>
    </div>
    <DataPopup
      :review="sectionToView"
      @close-review-data-popup="showSectionPopup = false"
      v-if="showSectionPopup"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SectionHeader from '@/components/SectionHeader'
import DataPopup from '@/views/admin/DataPopup.vue'

import SearchUsersMixin from '@/_mixins/search-users.mixin'
import DateFilterMixin from '@/_mixins/date-filter.mixin'

import Fuse from 'fuse.js'

export default {
  name: 'ModeratedPlans',
  components: { SectionHeader, DataPopup },
  mixins: [SearchUsersMixin, DateFilterMixin],
  data() {
    return {
      searchTerm: '',
      fuse: null,
      searchResults: [],
      limit: 10,
      offset: 0,
      expandedItem: null,
      sectionToView: null,
      showSectionPopup: false,
      lastPage: 0,
      dateRange: {
        start: null,
        end: null,
      },
      expandedDataArr: [],
      completeReviews: [],
    }
  },
  watch: {
    offset(newValue, oldValue) {
      this.offset = newValue
    },
    limit(newValue, oldValue) {
      this.limit = newValue
    },
  },
  computed: {
    ...mapGetters({
      plans: 'plan/plans',
      assignments: 'assignment/assignments',
      reviews: 'assignment/reviews',
      themes: 'criteria/themes',
      users: 'organisation/users',
    }),
    totalPages() {
      return Math.ceil(this.moderatedPlans.length / this.limit)
    },
    currentPage() {
      return this.offset / this.limit + 1
    },
    moderatedPlans() {
      // First let get a list of all review that have been completed
      // So all answers have an outcome
      const completedReviews = this.getCompletedReviews(this.reviews)

      // Now let's get all the assignments that have completed reviews
      const assignmentsWithCompletedReviews = completedReviews.map((review) => {
        const assignment = this.assignments.find(assignment => assignment.id === review.assignmentId && !!assignment.completedAt)

        return assignment
      }).filter((item) => item) // filter out undefined value from assignmentsWithCompletedReviews
      
      
      // Now that we knows these assignments have completed reviews 
      // we can figure out which plans have multiple assignments with completed reviews
      const multiplePlanIdsArray = this.getPlanIdsWithMultipleAssignments(assignmentsWithCompletedReviews)

      const mapMultiples = (planId) => {
        const plan = this.plans.find((plan) => plan.id === planId)
        
        const reviews = [...completedReviews].filter(
          (review) => {
            // const assignment = assignmentsWithCompletedReviews.find((assignment) => assignment.id === review.assignmentId)
            
            // return (!!assignment?.completedAt && !!review.outcome) && review.assignment.planId === planId
            return review.assignment.planId === planId
          }
        )

        const assignments = [...assignmentsWithCompletedReviews].filter(
          (assignment) => (assignment.planId === planId) && (!!assignment.completedAt)
        )

        return { plan, reviews, assignments }
      }

      // Return only the plans with multiple assignments.
      const res = multiplePlanIdsArray
        .map(mapMultiples)
        .filter((item) => item.assignments.length >= 2)
        .sort((a, b) => (a.plan.createdAt > b.plan.createdAt ? -1 : 1))

      return res
    },
    paginatedPlans() {
      const limit = this.offset + this.limit
      
      return this.moderatedPlans.slice(this.offset, limit)
    },
    results() {
      if (!this.searchTerm) {
        return [...this.moderatedPlans].slice(
          this.offset,
          this.offset + this.limit
        )
      }
      return [...this.moderatedPlans]
        .filter((a) => this.searchResults.includes(a.planId.ehcpId))
        .slice(this.offset, this.offset + this.limit)
    },
    viewingAudit() {
      if (Number.isNaN(this.expandedItem + this.offset)) {
        return []
      }

      const plan = this.moderatedPlans[this.expandedItem + this.offset]
    
      return plan
    },
    diffs() {
      if (Array.isArray(this.viewingAudit)) {
        return []
      }
      return [...this.themes]
        .map((section) => section.theme)
        .reduce((accum, theme) => {
          accum = {
            ...accum,
            [theme]: [],
          }
          for (const review of this.viewingAudit.reviews) {
            review.answers.forEach((answer) => {
              if (answer.theme === theme) {
                const outcome = this.$options.filters.formatGrade(
                  answer
                )
                accum[theme].push(outcome)
              }
            })
          }
          return accum
        }, {})
    },
    fromDate() {
      return new Date(this.dateRange.start)
    },
    toDate() {
      return new Date(this.dateRange.end)
    },
  },
  methods: {
    getCompletedReviews(reviews) {
      const completeReviews =  reviews.filter((review) => {
        // Test to see if all answers have an outcome
        // const answers = review.answers.filter((item) => {
        //   return item.outcome !== null
        // })

        // return answers.length === this.themes.length && !!review.completedAt
        return !!review.completedAt && (review.outcome !== null || review.outcome !== undefined)
      })

      return completeReviews
    },
    getPlanIdsWithMultipleAssignments(assignmentsObject) {
        const uniques = new Set()
        const multiple = new Set()

        if (!assignmentsObject) {
          return
        }

        assignmentsObject.forEach((assignment) => {
          if (!assignment) {
            return
          }

          if (uniques.has(assignment.planId)) {
            multiple.add(assignment.planId)

            return
          }

          uniques.add(assignment.planId)
        })

        return Array.from(multiple)
    },
    expandedData() {
      const answers = this.reviews.answers
      const diffs = this.diffs
      const themes = this.themes

      let arr

      themes.forEach(
        (section, index) =>
          (arr[index] = { ...arr[index], section: `Theme ${section.theme}` })
      )
      answers.forEach(
        (answer, index) =>
          (arr[index] = {
            ...arr[index],
            answer: answer || this.formatGrade,
          })
      )
      diffs.forEach((diff, index) => (arr[index] = { ...arr[index], diff }))

      this.expandedDataArr = arr
    },
    search(event) {
      if (!this.fuse) {
        const plans = [...this.moderatedPlans].map(({ audit, plan }) => {
          return { ...plan, name: a.userId.name }
        })
        this.fuse = new Fuse(plans, {
          includeScore: true,
          // includeMatches: true,
          ignoreLocation: true,
          threshold: 0.25,
          keys: ['plan.internalId', 'plan.caseOfficer', 'name'],
        })
      }
      const results = this.fuse.search(this.searchTerm)
      this.searchResults = results.map((r) => r.item.ehcpId)
    },
    expand(item) {
      if (this.expandedItem === item) {
        this.expandedItem = null
        return
      }
      this.expandedItem = item
    },
    showDiffs(diffs) {
      const reducer = (map, val) => {
        if (map[val] == null) {
          map[val] = 1
        } else {
          ++map[val]
        }
        return map
      }
      return Object.values(diffs.reduce(reducer, {})).length > 1
        ? 'background: red'
        : 'background: green'
    },
    numberOfDiffs(reviews) {

      const answers = reviews.map((item) => {
        return item.answers
      })

      const outcomes = answers.map((item) => {
        return item.map((answer) => {
          return { theme: answer.theme, grade: this.$options.filters.formatGrade(answer)}
        })
      })

      // Calculate and log the sum of differences
      const calculateSumOfDifferences = (data) => {
          // const expected = data.length
          const allItems = data.flat();

          const themes = allItems.map((item) => item.theme)
          const uniqueThemeSet = new Set(themes)

          let count = 0

          Array.from(uniqueThemeSet).forEach((theme) => {
            // get all the answer in that theme
            const themeArray = allItems.filter((item) => item.theme === theme)

            // get all the unique grades in that theme
            const uniqueGrades = new Set(themeArray.map((item) => item.grade))

            // If uniqueGrades is greater than 1, then there are differences in the theme
            // To count all differences add the length of uniqueGrades to the count
            if (uniqueGrades.size > 1) {
              count++
            }

            // If the length of the themeArray is not equal to the expected number of reviews
            // then there are missing reviews so count this as a difference
            // if (themeArray.length !== expected) {
            //   count++
            // }
          })

          return { count, uniqueThemeSet, allItems }
      };

      const { count, uniqueThemeSet, allItems } = calculateSumOfDifferences(outcomes)

      if (count === 4) {
        console.log('review', reviews)
        console.log('count', count)
        console.log('uniqueThemeSet', uniqueThemeSet)
        console.log('allItems', allItems)
        
        reviews.map((item) => {
          console.log('item', item.id)
        })
      }

      return count
    },
    showResults(section) {
      this.sectionToView = section
      this.showSectionPopup = true
    },
    previousPage() {
      this.offset = this.offset - this.limit < 0 ? 0 : this.offset - this.limit
    },
    nextPage() {
      this.offset =
        this.offset + this.limit > this.moderatedPlans.length
          ? 0
          : this.offset + this.limit
    },
    goToPage(page) {
      this.offset = page * this.limit
    },
    goToFunction(value) {
      this.offset =
        value > this.lastPage
          ? this.offset + this.limit
          : this.offset - this.limit
      this.lastPage = value
    },
    reviewAuthor(id) {
      const author = this.users.filter((u) => u.id === id)
      return author[0].name
    },
  },
  mounted() {
    this.$store.dispatch('plan/fetchPlans')
    this.$store.dispatch('assignment/fetchAssignments')
    this.$store.dispatch('review/fetchReviews')
    // this.$store.dispatch('user/fetchUsers')
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/components.scss';
@import '@/assets/styles/sections.scss';

.table-data-container {
  margin-top: 0.5rem;
  padding: 1rem;
  > * {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    > * {
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > :not(:first-child):not(:last-child) {
      flex: 1 0 220px;
      a {
        display: block;
        text-align: center;
      }
    }
    > :first-child {
      flex: 0 0 92px;
    }
    > :last-child {
      flex: 0 0 25px;
    }
  }
  > :nth-child(2n) {
    background: whitesmoke;
  }
}

.empty-state-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-table-container {
  display: flex;
  flex-direction: row;
  // overflow-x: scroll;
}

.table-size {
  width: 400px;
}

td,
th {
  height: 3rem;
  text-align: center;
}

tr:nth-child(even) {
  background: #f8f8f8;
}
tr:nth-child(odd) {
  background: #fff;
}

.list {
  &__container {
    grid-area: list;
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
  &__options {
    justify-self: flex-end;
    .input {
      max-width: 64px;
      padding-left: 1rem;
      font-weight: 600;
    }
  }
  &__header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 1rem 0;
    place-items: center;
    font-weight: 600;
    color: darkgrey;
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-flow: column;
    &--item {
      display: flex;
      flex-flow: column;
      width: 100%;
      padding: 1rem 0;
      border: 1px solid #eeedec;
      border-radius: 4px;
      box-shadow: none;
      // &:not(:last-of-type) {
      margin-bottom: 1rem;
      // }
      transition: box-shadow 200ms;
      &:hover {
        box-shadow: 0px 4px 8px #eeedec;
      }
    }
  }
  &__controls {
    justify-content: space-between;
  }
}

.round {
  border-radius: 50%;
  width: 22.5px;
  height: 22.5px;
  // margin-bottom: 7px;
}
.collapsed-data {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  max-height: 58px;
  place-items: center;
}
.expanded-data {
  max-height: 0;
  height: 100%;
  transition: height 200ms ease-in-out;
}
.show-expanded {
  max-height: 600px;
}

.control {
  display: flex;
  place-items: center;
  place-content: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eeedec;
  border-radius: 4px;
  background: transparent;
  box-shadow: none;
  transition: box-shadow 200ms;
  font-weight: 600;
  &:hover {
    box-shadow: 0 4px 8px #eeedec;
  }
}

.plan-details {
  // display: flex;
  // flex-flow: row;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  margin-top: 0.5rem;
  padding: 1rem;
}
.sections {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.section-header {
  width: 100%;
  text-align: center;
  line-height: 2rem;
  background-color: white;
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  // margin-top: -0.5rem;
  // margin-bottom: 0.5rem;
}

.section-header.grades {
  position: relative;
  color: inherit;
  font-weight: 600;
  text-transform: uppercase;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 2px;
  }
}

.audited-by {
  text-transform: none;
  font-weight: 400 !important;
}

.review-author {
  text-transform: none;
}

.search-params {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.search-bar {
  max-width: 400px;
}

.dateFilter {
  flex-grow: 1;
  flex-shrink: 0;
  padding-right: 0.5rem;
}

.outcome {
  &__Gold::after {
    border-bottom: 2px solid $gold-colour;
  }
  &__Silver::after {
    border-bottom: 2px solid $silver-colour;
  }
  &__Bronze::after {
    border-bottom: 2px solid $bronze-colour;
  }
}
// .outcome-outstanding::after {
//   border: 2px solid $outstanding-color;
// }
// .outcome-good::after {
//   border: 2px solid $good-color;
// }
// .outcome-requires_improvement::after {
//   border: 2px solid $ri-color;
// }
// .outcome-inadequate::after {
//   border: 2px solid $inadequate-color;
// }

.moderation-results {
  display: grid;
  gap: 10px;
  grid-template-columns: 100px repeat(3, 1fr) 50px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: 1fr;
  // align-items: flex-start;
  // justify-content: center;
  // width: 100%;
  // height: 100%;
  // overflow-x: scroll;
  // scroll-snap-type: x proximity;
}

.moderation-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // scroll-snap-align: center;
  background: #eeedec;
  max-height: 200px;
  // padding: 0.5rem 0;
  // overflow-x: scroll;
}

.key-lookup,
.diffs {
  max-width: 100px;
  background-color: #eeedec50;
}

.list__controls {
  grid-column: 1 / 2;
  justify-content: center;
  margin-bottom: 20px;
}

.list__controls,
.pagination-control {
  display: flex;
}
.pagination-control .control {
  border: none;
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.current-page {
  color: blue;
  text-decoration: underline;
}
.section-result {
  color: inherit;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

// .key-lookup
.section-id {
  padding: 4px;
  // &__diffs {
  //   height: 1rem;
  //   line-height: 2rem;
  // }
}

.list__content.empty {
  margin-top: 10vh;
}

// /deep/ .pagination li a {
//   color: black !important;
// }

// /deep/ .pagination li:hover {
//   a {
//     text-decoration: underline;
//   }
// }

// /deep/ .pagination .active a {
//   color: white !important;
// }
</style>
